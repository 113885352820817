<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <router-link
          class="d-flex justify-content-center mb-2"
          to="/"
        >
          <b-img
            fluid
            :src="$store.state.app.companyLogo"
            alt="Login V2"
            class="col-7 col-xl-5"
          />
        </router-link>
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-1"
        >
          {{ $t("actions.create-new-account") }}
        </b-card-title>
        <b-card-text class="mb-2">
          <!-- Make your app management easy and fun! -->
        </b-card-text>
        <div class="d-flex justify-content-center">
          <div class="pb-1">
            <ul style="list-style-type: none; display: contents">
              <locale />
            </ul>
          </div>
        </div>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-login-form mt-1"
            @submit.prevent="register"
          >
            <!-- firstname -->
            <b-form-group
              :label="$t('first-name')"
              label-for="register-firstname"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Firstname"
                rules="required|max:256"
                vid="firstname"
              >
                <b-form-input
                  id="register-firstname"
                  v-model="firstname"
                  name="register-firstname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="John"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- lastname -->
            <b-form-group
              :label="$t('last-name')"
              label-for="register-lastname"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Lastname"
                rules="required|max:256"
                vid="lastname"
              >
                <b-form-input
                  id="register-lastname"
                  v-model="lastname"
                  name="register-lastname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- firstname -->
            <b-form-group
              :label="$t('nick-name')"
              label-for="register-nickname"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nickname"
                rules="required|max:256"
                vid="nickname"
              >
                <b-form-input
                  id="register-nickname"
                  v-model="nickname"
                  name="register-nickname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Johnny"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              :label="$t('email')"
              label-for="login-email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
                rules="email|max:256"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                  @keydown.space.prevent
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone -->
            <b-form-group
              :label="$t('phone')"
              label-for="register-phone"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Phone"
                vid="phone"
                rules="numeric|max:14"
              >
                <b-form-input
                  id="register-phone"
                  v-model="phone"
                  name="register-phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- country -->
            <b-form-group
              :label="$t('country')"
              label-for="register-country"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Country"
                rules="required"
                vid="country"
              >
                <b-form-select
                  id="register-country"
                  v-model="country"
                  :options="countries"
                  name="register-country"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <!-- <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t("password") }}</label>
                <b-link
                  :disabled="isProcessing"
                  :to="{ name: 'forgot-password' }"
                >
                  <small>{{ $t("actions.forgot-password") }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:6|max:256"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!--  password confirmation-->
            <!-- <b-form-group
              :label="$t('password-confirmation')"
              label-for="register-password-confirmation"
            >
              <validation-provider
                #default="{ errors }"
                name="Password confirmation"
                vid="password_confirmation"
                rules="required|confirmed:Password"
                :custom-messages="{ confirmed: $t('validation-messages.password-confirmation') }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password-confirmation"
                    v-model="password_confirmation"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="register-password-confirmation"
                    :placeholder="$t('password-confirmation')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t("actions.remember-me") }}
              </b-form-checkbox>
            </b-form-group> -->

            <!--date of birth-->
            <b-form-group
              label-for="dob"
              :label="$t('account-setting-general.dob')"
            >
              <b-input-group>
                <flat-pickr
                  v-model="dob"
                  class="form-control"
                  :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d', maxDate:maxDate}"
                />
              </b-input-group>
            </b-form-group>
            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isProcessing"
            >
              <b-spinner
                v-show="isProcessing"
                class="mr-1"
                small
                variant="light"
              />
              <span>{{ $t("actions.register") }}</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <span>{{ $t("messages.already-have-account") }}</span>
          <b-link
            :disabled="isProcessing"
            :to="{ name: 'login' }"
          >
            <span>&nbsp;{{ $t("actions.sign-in-instead") }}</span>
          </b-link>
        </p>

        <!-- divider -->
        <!-- <div class="divider my-2">
          <div class="divider-text">
            {{ $t("or") }}
          </div>
        </div> -->

        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            href="javascript:void(0)"
            :disabled="isProcessing"
            @click="loginWithFacebook()"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="google"
            href="javascript:void(0)"
            :disabled="isProcessing"
            @click="loginWithGoogle()"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
        </div> -->
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BSpinner,
  BCard,
  BLink,
  BButton,
  BForm,
  // BFormCheckbox,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BCardText,
  BFormSelect,
  BImg,
  BInputGroup,
} from 'bootstrap-vue'
import { required, email, password } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import i18n from '@/libs/i18n'
import { initFbsdk, getUserDetails } from '@/auth/services/fb'
import { initGoogleSdk } from '@/auth/services/google'
import getLandingPageFor from '@/router/utils'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import RegisteredCountries from '@/const/registered-country'

export default {
  components: {
    BCard,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    // BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Locale,
    BSpinner,
    BImg,
    BInputGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isProcessing: false,
      status: '',
      firstname: '',
      lastname: '',
      userEmail: '',
      nickname: '',
      phone: '',
      password_confirmation: '',
      dob: '',
      sideImg: require('@/assets/images/pages/register-v2.png'),
      jaacImg: require('@/assets/images/logo/logo.svg'),
      maxDate: moment().subtract(7, 'years').format('YYYY-MM'),
      // validation
      required,
      email,
      password,
      country: null,
      countries: RegisteredCountries,
      language: null, // https://github.com/forxer/languages-list/blob/master/src/Languages.csv
      languages: [
        { value: null, text: 'Please select your naitve language' },
        { value: 'English', text: 'English' },
        { value: 'Spanish', text: 'Español,' },
        { value: 'Korean', text: '한국어' },
        { value: 'Japanese', text: '日本語' },
        { value: 'Vietnamese', text: 'Tiếng Việt' },
        { value: 'Hindi', text: 'हिन्दी, हिंदी' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    initFbsdk()
    initGoogleSdk()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },

    loginWithFacebook() {
      this.isProcessing = true
      window.FB.login(async () => {
        const details = await getUserDetails()
        // eslint-disable-next-line no-shadow
        const { id, name, email } = details
        const [firstname, lastname] = name.split(' ')
        const avatar = details.picture.data.url
        useJwt
          .socialLogin('facebook', {
            provider_id: id,
            name,
            email,
            avatar,
            firstname,
            lastname,
          })
          .then(res => {
            this.setUserAndRedirect(res)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },
    loginWithGoogle() {
      this.isProcessing = true
      window.gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(response => {
          const details = response.getBasicProfile()
          const fullName = details.getName()
          const names = fullName.split(' ')
          const user = {
            provider_id: details.getId(),
            email: details.getEmail(),
            name: details.getName(),
            avatar: details.getImageUrl(),
            firstname: names[0],
            lastname: names.length > 0 ? names[1] : '',
          }

          useJwt
            .socialLogin('google', user)
            .then(res => {
              this.setUserAndRedirect(res)
            })
            .catch(() => {
              this.isProcessing = false
            })
        })
        .catch(() => {
          this.isProcessing = false
        })
    },

    setUserAndRedirect(res) {
      this.isProcessing = false
      const userData = res.data.data.user
      useJwt.setToken(res.data.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$router
        .replace({ name: getLandingPageFor(userData.usertype, 'home') })
        .then(() => {
          this.$store.dispatch('authEvents/onUserLogin')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${i18n.tc('welcome')} ${userData.firstname} ${
                userData.lastname
              }`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `${i18n.tc('messages.successfully-logged-in-as')} ${
                userData.firstname
              } ${userData.lastname}.`,
            },
          },
          {
            position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
          })
        })
    },

    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .register({
              firstname: this.firstname,
              lastname: this.lastname,
              nickname: this.nickname,
              email: this.userEmail,
              phone: this.phone,
              country: this.country,
              language: localStorage.getItem('locale'),
              password: this.password,
              password_confirmation: this.password_confirmation,
              dob: this.dob,
            })
            .then(response => {
              const userData = response.data.data
              // useJwt.setToken(response.data.accessToken)
              // localStorage.setItem('userData', JSON.stringify(userData))
              // this.$router.replace('/login').then(() => {
              //   this.showSuccessMessage(response)
              // })

              this.$router.push({ name: 'login', params: { isRegistered: true } }).then(() => {
                this.showSuccessMessage(response)
              })

              const params = {
                name: `${this.firstname} private class`,
                is_default: 1,
                created_by: userData.id,
              }

              useJwt.createClass(params)

              // this.$router
              //   .replace({ name: getLandingPageFor(userData.usertype, 'home') })
              //   .then(() => {
              //     this.$store.dispatch('authEvents/onUserLogin')
              //     this.$toast({
              //       component: ToastificationContent,
              //       props: {
              //         title: `${i18n.tc('welcome')} ${userData.firstname} ${
              //           userData.lastname
              //         }`,
              //         icon: 'CoffeeIcon',
              //         variant: 'success',
              //         text: `${i18n.tc('messages.successfully-logged-in-as')} ${
              //           userData.firstname
              //         } ${userData.lastname}. `,
              //       },
              //     }, {
              //       position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
              //     })
              //   })
            })
            .catch(error => {
              if (error.response?.data?.errors) {
                this.$refs.registerForm.setErrors(error.response.data.errors)
              } else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
